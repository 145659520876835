/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getCustomer,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  }, 
  async getList(variables) {
    return API.graphql({
      query: queries.listCustomers,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables,
    })
  },  
  async update(input) {
    delete input.createdAt
    delete input.updatedAt
    delete input.owner
    return API.graphql({
      query: mutations.updateCustomer,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async delete(id) {
    return API.graphql({
      query: mutations.deleteCustomer,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },

}
